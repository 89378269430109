import { model } from '@telekomconsalting/dex-guru-model'

import { FilterOptions, TransactionTypes } from '../../model'

export enum TradingHistoryFilterNames {
  all = 'All',
  myOrders = 'My orders',
}

export const tradingHistoryFilters: Record<
  TradingHistoryFilterNames,
  Partial<FilterOptions> | ((account: model.Address) => Partial<FilterOptions>)
> = {
  [TradingHistoryFilterNames.all]: {
    transaction_types: [TransactionTypes.swap],
  },
  [TradingHistoryFilterNames.myOrders]: (account: model.Address): Partial<FilterOptions> => ({
    transaction_types: [TransactionTypes.swap, TransactionTypes.transfer],
    account,
  }),
}

export enum TradingHistoryMessages {
  loading = 'Loading transactions data…',
  empty = 'It looks like there are currently no swap TXs available.',
}
