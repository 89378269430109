import { useWeb3React } from '@telekomconsalting/core'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { openProfile } from '../../actions/traderProfileActions'
import { FilterOptions, TokenWithApprovalContext } from '../../model'
import { TraderProfileTabs } from '../../reducers/traderProfileReducer'
import amplitudeService from '../../services/amplitudeService'
import {
  setFilters,
  setRowsToShow,
  TxsLoaderAction,
  TxsLoaderReducerState,
} from '../../services/TxsLoader'
import { TradingHistoryFilterNames, tradingHistoryFilters } from './TradingHistory.enums'

type UseTradingHistoryManagerProps = {
  currentToken?: TokenWithApprovalContext
  state: TxsLoaderReducerState
  dispatch: React.Dispatch<TxsLoaderAction>
}

type UseTradingHistoryManagerReturn = {
  onClickTradingHistoryRow: (wallet?: string, txnId?: string) => void
  onAll: () => Promise<void>
  onMyOrder: () => Promise<void>
  onChangeRowsToShow: (amount: number) => void
}

const useTradingHistoryManager = ({
  currentToken,
  dispatch,
}: UseTradingHistoryManagerProps): UseTradingHistoryManagerReturn => {
  const { account } = useWeb3React()
  const history = useHistory()
  const reduxDispatch = useDispatch()
  const didMountRef = useRef(false)

  const handleAll = async (): Promise<void> => {
    amplitudeService.tradingHistoryChangeFilter({
      currentToken,
      filterName: TradingHistoryFilterNames.all,
    })
    dispatch(
      setFilters(tradingHistoryFilters[TradingHistoryFilterNames.all] as Partial<FilterOptions>)
    )
  }

  const handleMyOrder = async (): Promise<void> => {
    amplitudeService.tradingHistoryChangeFilter({
      currentToken,
      filterName: TradingHistoryFilterNames.myOrders,
    })
    const myOrdersFilter = tradingHistoryFilters[TradingHistoryFilterNames.myOrders]
    if (typeof myOrdersFilter === 'function' && account) {
      dispatch(setFilters(myOrdersFilter(account)))
    }
  }

  const handleChangeRowsToShow = React.useCallback(
    (amount: number): void => dispatch(setRowsToShow(amount)),
    [dispatch]
  )

  const handleClickTradingHistoryRow = async (
    walletAddress?: string,
    transactionAddress?: string
  ): Promise<void> => {
    if (!walletAddress || !transactionAddress) {
      return
    }
    reduxDispatch(
      openProfile({
        walletAddress,
        transactionAddress,
        prevPathname: history.location.pathname,
        tab: TraderProfileTabs.token,
      })
    )
  }

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }
    dispatch(
      setFilters(tradingHistoryFilters[TradingHistoryFilterNames.all] as Partial<FilterOptions>)
    )
    // need to reset filters when token had changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken?.id])

  return {
    onClickTradingHistoryRow: handleClickTradingHistoryRow,
    onAll: handleAll,
    onMyOrder: handleMyOrder,
    onChangeRowsToShow: handleChangeRowsToShow,
  }
}
export default useTradingHistoryManager
