import { useWeb3React } from '@telekomconsalting/core'
import React, { FC, Reducer, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setTradingHistory } from '../../actions'
import { State } from '../../reducers'
import { TxsLoaderAction, txsLoaderReducer, TxsLoaderReducerState } from '../../services/TxsLoader'
import useLoadTransactions from '../../services/TxsLoader/useLoadTransactions'
import { getMarketDisplayName } from '../../utils'
import FullHistoryLink from '../PoolActivity/FullHistoryLink'
import getIsPopupOpened from '../PoolActivity/helpers/getIsPopupOpened'
import TableWithFilters from '../TableWithFilter/TableWithFilters'
import getUpdateTradingHistoryFilters from './getUpdateTradingHistoryFilters'
import fetchTradingHistoryTxs from './helpers/fetchTradingHistoryTxs'
import initTradingHistoryState from './helpers/initTradingHistoryState'
import mapResponseDataForTradingHistory from './mapResponseDataForTradingHistory'
import { TradingHistoryFilterNames, TradingHistoryMessages } from './TradingHistory.enums'
import TradingHistoryRow from './TradingHistoryRow'
import useTradingHistoryManager from './useTradingHistoryManager'

interface TradeHistoryProps {
  fullSidebarUrl: string
}

const TradingHistory: FC<TradeHistoryProps> = (props) => {
  const { account } = useWeb3React()

  const reduxDispatch = useDispatch()

  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const currentTokenRecord = React.useMemo(
    () => ({
      id: currentToken?.id,
      marketType: currentToken?.marketType,
    }),
    [currentToken]
  )

  const uiState = useSelector((state: State) => state.ui)
  const isPopupOpened = getIsPopupOpened(uiState)

  const [state, dispatch] = useReducer<Reducer<TxsLoaderReducerState, TxsLoaderAction>>(
    txsLoaderReducer,
    initTradingHistoryState
  )

  useEffect(() => {
    const origin = state.filters.account ? 'my_orders' : 'all'
    reduxDispatch(setTradingHistory(state.currentData, origin))
  }, [reduxDispatch, state.currentData, state.filters.account])

  // load, refresh logic
  useLoadTransactions({
    prepareParams: getUpdateTradingHistoryFilters,
    mapData: mapResponseDataForTradingHistory,
    dispatch,
    currentTokenRecord,
    filters: state.filters,
    rowsToShow: state.rowsToShow,
    shouldStopRefresh: isPopupOpened,
    fetchDataFn: fetchTradingHistoryTxs,
    name: '💰th', // trading-history
  })

  // control over filters
  const tradingHistoryManager = useTradingHistoryManager({
    currentToken,
    state,
    dispatch,
  })

  const buttons = React.useMemo(
    () =>
      account
        ? [
            {
              title: TradingHistoryFilterNames.all.toString(),
              value: 'all',
              onClick: (): void => {
                tradingHistoryManager.onAll()
              },
            },
            {
              title: TradingHistoryFilterNames.myOrders.toString(),
              value: 'myOrders',
              onClick: (): void => {
                tradingHistoryManager.onMyOrder()
              },
            },
          ]
        : undefined,
    [account] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getTradingHistoryMessage = (): string => {
    if (state.isLoading) {
      return TradingHistoryMessages.loading
    }
    if (state.filters.account) {
      const tokenSymbol = getMarketDisplayName(currentToken)
      return `It looks like there are currently no indexed swap TXs with ${tokenSymbol} on your connected wallet`
    }
    return TradingHistoryMessages.empty
  }

  const noDataMessage = getTradingHistoryMessage()

  return (
    <>
      <TableWithFilters
        title="Trading History"
        modifier="trading-history"
        filterButtons={buttons}
        renderRow={TradingHistoryRow}
        token={currentToken}
        currentData={state.currentData}
        prevData={state.previousData}
        noDataMessage={noDataMessage}
        isLoadingRows={state.isLoading}
        refreshTimestamp={state.refreshTimestamp as number | undefined}
        refreshed={state.isJustRefreshed}
        onClickRow={tradingHistoryManager.onClickTradingHistoryRow}
        suffixContent={<FullHistoryLink to={props.fullSidebarUrl} />}
        account={account}
        onChangeRowsToShow={tradingHistoryManager.onChangeRowsToShow}
      />
    </>
  )
}
export default TradingHistory
