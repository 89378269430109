import { FilterOptions, MarketType, TokenId } from '../../model'

const getUpdateTradingHistoryFilters = ({
  currentTokenRecord,
  filters,
  rowsToShow,
}: {
  currentTokenRecord: { id?: TokenId; marketType?: MarketType }
  filters: FilterOptions
  rowsToShow: number
}): { filters: FilterOptions; useLastEndpoint: boolean } => {
  const options = {
    ...filters,
    current_token_id: currentTokenRecord?.id,
    limit: rowsToShow,
  }

  return { filters: options, useLastEndpoint: !filters.account }
}

export default getUpdateTradingHistoryFilters
