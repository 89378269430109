import { FilterOptions } from '../../../model'
import { TxsLoaderReducerState } from '../../../services/TxsLoader'
import { TradingHistoryFilterNames, tradingHistoryFilters } from '../TradingHistory.enums'

const initTradingHistoryState: TxsLoaderReducerState = {
  isLoading: false,
  filters: tradingHistoryFilters[TradingHistoryFilterNames.all] as Partial<FilterOptions>,
  currentData: undefined,
  previousData: undefined,

  isJustRefreshed: false,
}

export default initTradingHistoryState
