import classNames from 'classnames'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { financialFormatLowValueHighPrecision } from '../../helpers/priceAndAmountHelpers'
import { isValidCategory } from '../../helpers/validators'
import { Address, TradeBarData, TransactionModel } from '../../model'
import { State } from '../../reducers'
import { buildTraderProfileUrl } from '../../routes'
import {
  getCurrencyName,
  getMarketDisplayNameBySymbols,
  getNetworkConfig,
  getTokenAddress,
  pickValueBasedOnCurrency,
} from '../../utils'
import { CategoryIcon } from '../CategoryIcon/CategoryIcon'
import { isOwnTx } from '../Sidebar/sidebarUtils'
import TimeAgo from '../TimeAgo/TimeAgo'
import TokenValueBar from '../TokenValueBar'
import { getDeltaClassName, getWalletCategory } from '../Transactions/helpers'
import TransactionOwn from '../Transactions/TransactionOwn'

interface TradingHistoryRowProps extends TransactionModel {
  modifier: string
  classAnimated: string
  option?: number
  tradeBarData?: TradeBarData
  onClickRow?: (wallet?: string, txnId?: string) => void
  account?: Address | null
}

const TradingHistoryRow: FC<TradingHistoryRowProps> = (props) => {
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const selectedCurrency = useSelector((state: State) => state.currency)
  const isMobile = useSelector((state: State) => state.isMobile)

  const tokenNetwork = currentToken?.network

  if (!currentToken || !tokenNetwork || !props.fromAddress || !props.toAddress) {
    return null
  }

  const networkConfig = getNetworkConfig(tokenNetwork)

  const deltaClassName = getDeltaClassName(
    props.transactionType,
    currentToken?.id,
    props.fromAddress,
    props.modifier
  )

  const isTransactionTypeSwap = props.transactionType === 'swap'

  const amount = isTransactionTypeSwap
    ? pickValueBasedOnCurrency(selectedCurrency, props.amountStable, props.amountNative || 0)
    : props.amountStable || 0

  const currentTokenAddress = getTokenAddress(currentToken).toLowerCase()
  const currentTokenIndex = props.tokenAddresses.indexOf(currentTokenAddress)
  const tokenPrice = isTransactionTypeSwap
    ? pickValueBasedOnCurrency(
        selectedCurrency,
        props.pricesStable && props.pricesStable[currentTokenIndex],
        props.pricesNative && props.pricesNative[currentTokenIndex]
      )
    : (props.pricesStable && props.pricesStable[0]) || 0

  const onClick = (): void => {
    if (props.onClickRow && props.wallets?.length) {
      props.onClickRow(props.wallets[0], props.transactionAddress)
    }
  }

  const currencyDisplayName = getCurrencyName(tokenNetwork, selectedCurrency)

  const ownTrade = isOwnTx(props.wallets, props.account)

  const walletCategory = getWalletCategory(props.walletsCategories)

  const indexFrom = isTransactionTypeSwap ? props.tokenAddresses.indexOf(props.fromAddress) : 0
  const indexTo = isTransactionTypeSwap ? props.tokenAddresses.indexOf(props.toAddress) : 0

  if (indexFrom === -1 || indexTo === -1) {
    return null
  }

  const amountFrom = props.amounts[indexFrom]
  const amountTo = props.amounts[indexTo]

  const renderData = (
    <>
      <div className="type" />
      <div className="own">{ownTrade && <TransactionOwn />}</div>
      <div className="amount">
        <div className="amount__value">
          <div
            key={`${amountFrom}-${props.tokenAddresses[indexFrom]}`}
            className="value"
            title={financialFormatLowValueHighPrecision(amountFrom.toFixed(currentToken.decimals))}>
            {financialFormatLowValueHighPrecision(amountFrom)}
          </div>
          <div
            key={`${amountTo}`}
            className="value"
            title={financialFormatLowValueHighPrecision(amountTo)}>
            {financialFormatLowValueHighPrecision(amountTo)}
          </div>
        </div>
      </div>
      <div className="token">
        <div className="token__symbol">
          <div
            key={`${props.symbols[indexFrom]}-${props.tokenAddresses[indexFrom]}-from`}
            className="symbol"
            title={getMarketDisplayNameBySymbols({ symbol: props.symbols[indexFrom] })}>
            {getMarketDisplayNameBySymbols({ symbol: props.symbols[indexFrom] })}
          </div>
          <div
            key={`${props.symbols[indexTo]}-${props.tokenAddresses[indexTo]}-to`}
            className="symbol"
            title={getMarketDisplayNameBySymbols({ symbol: props.symbols[indexTo] })}>
            {getMarketDisplayNameBySymbols({ symbol: props.symbols[indexTo] })}
          </div>
        </div>
      </div>
      <div className="price">
        <div className="price__value">
          {(selectedCurrency === 'USD' || !isTransactionTypeSwap) && (
            <span className="sign">$</span>
          )}
          <span
            className="value"
            title={financialFormatLowValueHighPrecision(
              props.option === 0
                ? financialFormatLowValueHighPrecision(amount)
                : financialFormatLowValueHighPrecision(tokenPrice)
            )}>
            {props.option === 0
              ? financialFormatLowValueHighPrecision(amount, {
                  roundBigNumbersFrom: 'M',
                })
              : financialFormatLowValueHighPrecision(tokenPrice)}
          </span>
          {selectedCurrency !== 'USD' && isTransactionTypeSwap && (
            <span className="sign">&nbsp;{currencyDisplayName}</span>
          )}
        </div>
        {props.tradeBarData && isTransactionTypeSwap && (
          <div className="meter">
            <TokenValueBar
              shouldDisplay={props.option === 0}
              tradeBarData={props.tradeBarData}
              tradeAmount={amount}
              selectedCurrency={selectedCurrency}
              isPositive={deltaClassName === 'up'}
            />
          </div>
        )}
      </div>
      <div className="emoji">
        <div className="icon">
          {isValidCategory([walletCategory]) && <CategoryIcon category={walletCategory} />}
        </div>
      </div>
      <TimeAgo timestamp={props.timestamp} />
    </>
  )

  const commonProps = {
    className: classNames(
      'history-content__trow',
      `history-content__trow--${deltaClassName}`,
      props.classAnimated,
      { 'history-content__trow--own': ownTrade }
    ),
    'data-testid': `table-row-${deltaClassName}`,
  }

  const rowUrl = buildTraderProfileUrl({
    urlParams: {
      walletAddress: props.wallets ? props.wallets[0] : '',
      tradeId: props.transactionAddress,
    },
    filters: {
      token_id: currentToken?.id,
    },
  })

  return (
    <>
      {!isMobile && (
        <Link {...commonProps} to={rowUrl} onClick={onClick}>
          {renderData}
        </Link>
      )}
      {isMobile && (
        <a
          href={`${networkConfig.block_explorer.url}/tx/${props.transactionAddress}`}
          target="_blank"
          rel="noopener noreferrer"
          {...commonProps}>
          {renderData}
        </a>
      )}
    </>
  )
}
export default TradingHistoryRow
